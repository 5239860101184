<template>
  <a-modal
      :title="tests"
      :visible="disabled"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="oneDiv">
      <p>工作组编号：</p>
      <a-input class="input" v-model="formData.code"/>
    </div>
    <div class="oneDiv">
      <p>工作组名称：</p>
      <a-input class="input" v-model="formData.groupName"/>
    </div>
    <div class="oneDiv">
      <p>所属组织：</p>
      <a-input class="input" v-model="formData.company"/>
    </div>
    <div class="oneDiv">
      <p>地区：</p>
      <a-cascader
        v-model="region"
        @change="onChange"
				class="input"
				:options="options"
				placeholder="请选择地区"
				style="width: 300px"
			/>
    </div>
    <div class="oneDiv">
      <p>村庄：</p>
      <a-select
				mode="multiple"
        @change="handleChange"
				placeholder="请选择村庄"
        :default-value="chooseList"
				class="input"
			>
				<a-select-option
					:value="item.id"
					v-for="(item, index) in villageList"
					:key="index"
				>
					{{ item.orgName }}
				</a-select-option>
			</a-select>
    </div>
    <div class="oneDiv">
      <p>负责人：</p>
      <a-input class="input" v-model="formData.leader"/>
    </div>
  </a-modal>
</template>

<script>
export default {
  inject: ['reload'],
  name: "workChange",
  data() {
    return {
      confirmLoading: false,
      options: [],
      region: '',
      villageList: [],
      chooseList: [],
    };
  },
  props:['disabled',"formData","tests"],
  mounted() {
		this.getArea()
    this.chooseList = this.formData.orgIdList
	},
  methods: {
    // 查看/修改提交
    handleOk() {
      let list = []
      let newList = []
      if(this.formData.orgList !== null) {
        if(this.formData.orgIdList !== null) {
          this.formData.orgList.map(item => {
            this.formData.orgIdList.push(item.id)
          })
          list = this.formData.orgIdList
          newList = Array.from(new Set(list))
          this.formData.orgIdList = newList
        } else {
          this.formData.orgIdList = []
           this.formData.orgList.map(item => {
            this.formData.orgIdList.push(item.id)
          })
          list = this.formData.orgIdList
          newList = Array.from(new Set(list))
          this.formData.orgIdList = newList
        }
      }
      if(this.tests === '查看工作组') {
         return this.$emit('getChangeModal',false)
      } else {
        if(!this.formData.code || !this.formData.groupName || !this.formData.company || !this.formData.leader) {
         return this.$message.warning('请填写完整')
        } else {
          this.$axios.put(this.api+'/group/update', this.formData).then(
            res=>{
              if(res.code === 200){
                this.$message.success('修改成功')
                this.$emit('getChangeModal',false)
                this.reload()
              }
            }
        )
        }
      }
    },
    // 获取地区
		getArea() {
			this.$axios.get(this.api + '/project/search').then((res) => {
				if (res.code === 200) {
          this.options = this.listToTree(res.data)
				}
			})
		},
    listToTree(list, parentId = '') {
			return list
				.filter((item) => item.parentId === parentId)
				.map((item) => ({
					...item,
					label: item.orgName,
					value: item.id,
					children: this.listToTree(list, item.id),
				}))
		},
    onChange() {
      if(this.region.length === 4) {
        this.$axios.get(this.api + `/org/list?parentId=${this.region[3]}`).then((res) => {
				if (res.code === 200) {
					this.villageList = res.data
				}
			})
      }
    },
    handleChange(value) {
			this.chooseList = value
			this.formData.orgIdList = value
		},
    handleCancel(e) {
      this.$emit('getChangeModal',false)
    },
  },
}
</script>

<style scoped>
.input{
  width: 200px;
}
.oneDiv{
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
}
.oneDiv >p{
  width: 100px;
}
</style>
