<template>
  <a-modal
      title="新增工作组"
      :visible="disabled"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      okText="提交"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="oneDiv">
      <p>工作组编号：</p>
      <a-input class="input" v-model="formData.code"/>
    </div>
    <div class="oneDiv">
      <p>工作组名称：</p>
      <a-input class="input" v-model="formData.groupName"/>
    </div>
    <div class="oneDiv">
      <p>所属组织：</p>
      <a-input class="input" v-model="formData.company"/>
    </div>
    <div class="oneDiv">
      <p>地区：</p>
      <a-cascader
      v-model="region"
				class="input"
				:options="options"
        @change="onChange"
				placeholder="请选择地区"
				style="width: 300px"
			/>
    </div>
    <div class="oneDiv">
      <p>村庄：</p>
      <a-select
				mode="multiple"
        @change="handleChange"
				placeholder="请选择村庄"
        :default-value="chooseList"
				class="input"
			>
				<a-select-option
					:value="item.id"
					v-for="(item, index) in villageList"
					:key="index"
				>
					{{ item.orgName }}
				</a-select-option>
			</a-select>
    </div>
    <div class="oneDiv">
      <p>负责人：</p>
      <a-input class="input" v-model="formData.leader"/>
    </div>
  </a-modal>
</template>

<script>
export default {
  inject: ['reload'],
  name: "workerAdd",
  data() {
    return {
      confirmLoading: false,
      formData:{},
      options: [],
      region: '',
      villageList: [],
      chooseList: [],
    };
  },
  props:['disabled'],
  mounted() {
		this.getArea()
	},
  methods: {
    handleOk() {
      this.$axios.post(this.api+'/group/add',this.formData).then(
          res=>{
            if(res.code === 200){
              this.$emit('showModal',false)
              this.$message.success('添加成功')
              this.formData = {}
              this.reload()
            }
          }
      )
    },
     // 获取地区
		getArea() {
			this.$axios.get(this.api + '/project/search').then((res) => {
				if (res.code === 200) {
					this.options = this.listToTree(res.data)
				}
			})
		},
    listToTree(list, parentId = '') {
			return list
				.filter((item) => item.parentId === parentId)
				.map((item) => ({
					...item,
					label: item.orgName,
					value: item.id,
					children: this.listToTree(list, item.id),
				}))
		},
    onChange() {
      if(this.region.length === 4) {
        this.$axios.get(this.api + `/org/list?parentId=${this.region[3]}`).then((res) => {
				if (res.code === 200) {
          this.villageList = res.data
				}
			})
      }
    },
    handleChange(value) {
			this.chooseList = value
			this.formData.orgIdList = value
		},
    handleCancel(e) {
      this.$emit('showModal',false)
      this.formData = {}
    },
  },
}
</script>

<style scoped>
.input{
  width: 200px;
}
.oneDiv{
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
}
.oneDiv >p{
  width: 100px;
}
</style>
