<template>
  <div class="home">
	  <div class="mianbao">
	  	<div class="breadcrumb">
	  		<span style="color: #fff;">
	  			系统设置>
	  		</span>
	  		<span style="color:#016ae0;">
	  			工作组管理
	  		</span>
	  	</div>
	  </div>
    <div class="header">
      <a-input class="input" v-model="groupName" placeholder="请输入工作组名称"/>
      <a-button type="primary" icon="search" @click="getProjectList">查询</a-button>
      <a-divider type="vertical" />
      <a-button type="primary" icon="plus" @click="showModal">新增工作组</a-button>
    </div>
    <workerAdd
        :disabled="disabled"
        @showModal="getData"
    >
    </workerAdd>
    <div class="main">
    <vue-scroll :ops="ops" style="width:100%;">
		  <wordertable :list='data' />
    </vue-scroll>
    </div>
  </div>
</template>

<script>
import workerAdd from "./modal/workerAdd";
import wordertable from './modal/wokertable.vue'
export default {
  name: "worker",
  data(){
    return{
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#F5F5F5",//滚动条颜色
          opacity: 0.5,//滚动条透明度
          "overflow-y": "hidden"
        }
      },
      disabled:false,
      data:[],
      groupName: '', //工作组名称
      groupIdList: sessionStorage.getItem('groupId'),
    }
  },
  components:{
    workerAdd,
	wordertable
  },
  mounted() {
    this.getProjectList()
  },
  methods:{
    showModal(){
      this.disabled = true
    },
    getData(data){
      this.disabled = data
      this.getProjectList()
    },
    // 查询工作组信息
    getProjectList(){
      console.log(this.groupIdList)
      let groupName = this.groupName
      let groupIdList = this.groupIdList
      this.$axios.get(this.api+'/group/list', {
        params: {
          groupName,
          groupIdList
        }
      }).then(
          res=>{
            if(res.code === 200){
              this.data = res.data.list
            }
          }
      )
    },

  }
}
</script>

<style scoped>
	div /deep/ .header>.ant-input{
		color: #fff;
		background-color: rgba(7, 23, 46, 1.0);
	}
.home{
  height: 100%;
  padding: 15px;
  background: url(../../assets/bg01.jpg) no-repeat;
  background-size: 100% 100%;
}
	.mianbao{
		height:40px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* background-color: yellow; */
	}
.header{
  width: 100%;
  border: 1px solid #016ae0;
  border-radius: 8px;
  height: 60px;
  text-align: left;
  padding: 15px;
}
.main{
  height: calc(100% - 120px);
  border: 1px solid #016ae0;
  border-radius: 8px;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px;
}
.input{
  width: 180px;
  margin-right: 20px;
}
</style>
