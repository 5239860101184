<template>
	<div class="table">
		<div class="header">
			<div class="list">
				<div
					class="item"
					:style="{ width: item.width + '%' }"
					v-for="(item, index) in columns"
					:key="index"
				>
					{{ item.title }}
				</div>
			</div>
		</div>
		<div class="table_body">
			<div class="body" v-for="item in list" :key="item.treeId">
				<div class="list">
					<div class="item" style="width: 20%">
						{{ item.code }}
					</div>
					<div class="item" style="width: 20%">
						{{ item.groupName }}
					</div>
					<div class="item" style="width: 20%">
						{{ item.company }}
					</div>
					<div class="item" style="width: 20%">
						<a-tag color="blue" v-for="item1 in item.orgList" :key="item1.id">
						  {{ item1.orgName }}
						</a-tag>
					</div>
					<div class="item" style="width: 10%">
						{{ item.leader }}
					</div>
					<div class="item" style="width: 20%">
						{{ item.createTime }}
					</div>
					<div class="item" style="width: 20%">
						<a-button type="primary" @click="handleSee(item)"
							>查看</a-button
						>
						<a-button
							type="primary"
							style="margin: 0 20px"
							@click="showChangeModal(item)"
							>修改</a-button
						>
            <a-popconfirm
				       	style="display: flex; align-items: center"
				       	title="确认删除"
				       	placement="bottom"
				       	ok-text="确认"
				       	cancel-text="取消"
				       	@confirm="deleteData(item.groupId)"
				       >
						  <a-button type="danger">删除</a-button>
            </a-popconfirm>
					</div>
				</div>
			</div>
		</div>
		<!-- 修改/查看工作组 -->
		<workChange
			:disabled="disabled"
			:formData="formData"
			@getChangeModal="getChangeModal"
			:tests="tests"
		></workChange>
	</div>
</template>

<script>
import workChange from './workChange'

export default {
	inject: ['reload'],
	props: {
		list: Array,
	},
	components: {
		workChange,
	},
	data() {
		return {
			visible: false,
			columns: [
				{
					title: '工作组编号',
					dataIndex: 'name',
					key: 'name',
					width: '20',
				},
				{
					title: '工作组名称',
					dataIndex: 'age',
					key: 'age',
					width: '20',
				},
				{
					title: '所属组织单位',
					dataIndex: 'address',
					key: 'address',
					width: '20',
				},
				{
					title: '地区',
					dataIndex: 'orgList',
					key: 'orgList',
					width: '20',
				},
				{
					title: '负责人',
					dataIndex: 'address',
					key: 'address',
					width: '10',
				},
				{
					title: '创建时间',
					dataIndex: 'address',
					key: 'address',
					width: '20',
				},
				{
					title: '操作',
					key: 'action',
					dataIndex: 'tags',
					width: '20',
				},
			],
			tests: '',
			disabled: false,
			formData: {},
		}
	},
	mounted() {
		console.log(this.list)
	},
	methods: {
		handleDetail() {
			this.$router.push('/except/detail')
		},
		handleCancel() {
			this.visible = false
		},
		// 修改
		showChangeModal(record) {
			this.tests = '修改工作组'
			this.disabled = true
			this.formData = record
		},
		getChangeModal() {
			this.disabled = false
		},
		// 删除工作组
		deleteData(groupId) {
			this.$axios
				.delete(this.api + '/group/delete/' + groupId)
				.then((res) => {
					if (res.code === 200) {
						this.$message.success('删除成功')
						this.reload()
					}
				})
		},
		handleSee(record) {
			this.tests = '查看工作组'
			this.disabled = true
			this.formData = record
		},
	},
}
</script>

<style scoped>
.table {
	width: 100%;
	height: 100%;
	color: #fff;
	/* background-color: red; */
}
.yuan {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: red;
	margin-right: 5px;
}
.item1 > div {
	display: flex;
}
.yi > div,
.wei > div {
	margin-right: 10px;
	width: 4em;
}
.wei > div:hover {
	cursor: pointer;
}
.duoxuan {
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.duoxuan > div {
	width: 10px;
	height: 10px;
	border: 1px solid #ccc;
	background-color: #fff;
}
.header {
	display: flex;
	height: 50px;
	background-color: #1f325e;
	border-bottom: 1px solid #005ea1;
	/* align-items: center; */
}
.header .list {
	background-color: #1f325e;
	width: calc(100% - 40px);
	display: flex;
	align-items: center;
}
.table_body {
	height: calc(100% - 50px);
	overflow-y: auto;
}
.table_body .body {
	display: flex;
	min-height: 50px;
	border-bottom: 1px solid #005ea1;
}
.table_body .list {
	width: calc(100% - 40px);
	display: flex;
	align-items: center;
}
.item{
	display: flex;
	align-items: center;
    flex-wrap: wrap;
	padding: 0 20px;
}
.ant-tag-blue {
		margin: 8px 2px;
	}
.model_item {
	display: flex;
	line-height: 3em;
}
/* 	.model_item1{
		width:100%;
		justify-content: center;
	} */
</style>
